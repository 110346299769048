<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Ventancat
        </h2>
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Recover"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Recuperación de la contraseña.
          </b-card-title>
          <b-card-text class="mb-2">Se le enviará un correo con la nueva contraseña a la cuenta de correo asociada al usuario</b-card-text>
          <!-- form -->
          <validation-observer
            ref="recoverForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-recover-form mt-2"
              @submit.prevent="recover"
            >
              <!-- email -->
              <b-form-group
                label="Email de la cuenta"
                label-for="recover-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="recover-email"
                    v-model="userEmail"
                    name="recover-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Restaurar Password
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Recover-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
  export default {
    components: {
      VuexyLogo,
      BRow,
      BImg,
      BCol,
      BLink,
      BButton,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        status: '',
        userEmail: '',
        sideImg: require('@/assets/images/pages/register-v2.svg'),
        // validation
        required,
        email,
      }
    },
    methods: {
      recover() {
        this.$refs.recoverForm.validate().then(success => {
          if (success) {
                this.$http.post("/api/recoverUser",{usermail : this.userEmail}).then((response) => {
                  if (response.data) {
                    window.console.log(response.data);
                    window.location.href = "https://app.ventancatgrup.com/recoverOk";
                  }else {
                    alert('no se ha podido enviar el correo')
                  }
                });   
          }
        })
      },
    },          
  }
</script>